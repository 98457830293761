import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { queryClient } from 'src/config/query';
import { COMMON_QUERY_KEYS } from 'src/constants/query';
import {
  IAddress,
  ICity,
  ICountry,
  INeighborhood,
  IState,
  IStreet,
} from 'src/interfaces/models/IAddress';
import { adminPublicAxios } from 'src/services/api/adminPublic';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IAxiosResponseAPI,
} from 'src/services/api/interfaces';

export async function getAddresses(
  config?: AxiosRequestConfig,
): Promise<IAxiosResponseAPI<IAddress>> {
  const response = await adminPublicAxios.get('addresses', config);

  if (response.status === 201) {
    queryClient.invalidateQueries([COMMON_QUERY_KEYS.NEIGHBORHOODS]);
    queryClient.invalidateQueries([COMMON_QUERY_KEYS.STREETS]);
  }

  return response;
}

interface IAddresByZipCode {
  created?: boolean;
  id?: number;
  city?: ICity;
  neighborhood?: INeighborhood;
  street?: IStreet;
  latitude?: string;
  longitude?: string;
}
export async function getAddressByZipCode(
  zipCode: string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<IAddresByZipCode>> {
  const response = await adminPublicAxios.get<IAddresByZipCode>(
    `addresses/${zipCode}`,
    config,
  );

  if (response.data.created) {
    queryClient.invalidateQueries([COMMON_QUERY_KEYS.NEIGHBORHOODS]);
    queryClient.invalidateQueries([COMMON_QUERY_KEYS.STREETS]);
  }

  return response;
}

export async function getCountries(
  config?: AxiosRequestConfig,
): Promise<IAxiosResponseAPI<ICountry>> {
  const response = await adminPublicAxios.get('countries', config);

  return response;
}

export async function getStates(
  config?: AxiosRequestConfig,
): Promise<IAxiosResponseAPI<IState>> {
  const response = await adminPublicAxios.get('states', config);

  return response;
}

export async function getCities(
  config?: AxiosRequestConfig,
): Promise<IAxiosResponseAPI<ICity>> {
  const response = await adminPublicAxios.get('cities', config);

  return response;
}

export async function getNeighborhoods(
  config?: AxiosRequestConfig,
): Promise<IAxiosResponseAPI<INeighborhood>> {
  const response = await adminPublicAxios.get('neighborhoods', config);

  return response;
}

export function useNeighborhoodsQuery(data?: IApiUseQueryData<INeighborhood>) {
  return useQuery<IApiUseQueryFnData<INeighborhood>>(
    [COMMON_QUERY_KEYS.NEIGHBORHOODS, data?.requestConfig?.params],
    () => getNeighborhoods(data?.requestConfig),
    data?.queryOptions,
  );
}

export async function getStreets(
  config?: AxiosRequestConfig,
): Promise<IAxiosResponseAPI<IStreet>> {
  const response = await adminPublicAxios.get('streets', config);

  return response;
}

export function useStreetsQuery(data?: IApiUseQueryData<IStreet>) {
  return useQuery<IApiUseQueryFnData<IStreet>>(
    [COMMON_QUERY_KEYS.STREETS, data?.requestConfig?.params],
    () => getStreets(data?.requestConfig),
    data?.queryOptions,
  );
}
