import {
  ForwardRefRenderFunction,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';

import CheckIcon from '@mui/icons-material/Check';
import { TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from 'src/components/Button';

import { ConfirmDialogRef, ConfirmDialogProps } from './interfaces';

const ConfirmDialog: ForwardRefRenderFunction<
  ConfirmDialogRef,
  ConfirmDialogProps
> = (
  {
    title,
    description,
    loading,
    confirmVariant = 'contained',
    confirmColor = 'error',
    onConfirm,
    canDelete,
    ...rest
  },
  ref,
) => {
  const [open, setOpen] = useState(false);
  const [shouldDelete, setShouldDelete] = useState(false);

  useImperativeHandle(ref, () => ({
    show: () => {
      setOpen(true);
      setShouldDelete(false);
    },
    hide: () => setOpen(false),
    isDelete: () => shouldDelete,
  }));

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth="xs"
      {...rest}
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <DialogContentText variant="body1">{description}</DialogContentText>

        {canDelete && (
          <>
            <DialogContentText variant="body1" color="error">
              <div>Para apenas Desativar, clique no botão DESATIVAR.</div>
              <div>
                <strong>Essa ação pode ser revertida.</strong>
              </div>
              <br />
              <div>
                Para excluir definitivamente o cadastro, preencha o campo abaixo
                e clique no botão EXCLUIR.
              </div>
              <div>
                <strong>Essa ação é irreversível.</strong>
              </div>
            </DialogContentText>

            <TextField
              color="secondary"
              fullWidth
              placeholder="Digite 'Excluir' sem aspas"
              size="small"
              onChange={(e) => setShouldDelete(e.target.value === 'Excluir')}
            />

            {shouldDelete && (
              <DialogContentText variant="body1" color="error">
                <i>
                  Informações vinculadas a esse registro poderão ser excluidas,{' '}
                  <strong>e não podem ser revertidas!</strong>
                </i>
              </DialogContentText>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          startIcon={<CheckIcon />}
          loading={loading}
          color={confirmColor}
          variant={confirmVariant}
          onClick={onConfirm}
        >
          {!canDelete ? 'Confirmar' : shouldDelete ? 'Excluir' : 'Desativar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(ConfirmDialog);
