import yup from 'src/libs/yup';
import type { InferType } from 'yup';

const yupValidate = async <S extends yup.BaseSchema>(
  schema: S,
  data = {},
  options = {},
) => {
  const formErrors: Record<string, string> = {};
  let success = false;
  let validData = {};

  try {
    const result = await schema.validate(data, {
      abortEarly: false,
      stripUnknown: true,
      ...options,
    });

    success = true;
    validData = result;
  } catch (e) {
    success = false;

    if (yup.ValidationError.isError(e)) {
      e.inner.forEach((error) => {
        if (error.path) {
          let label = '';
          try {
            label = yup.reach(schema, error.path).describe().label || '';
          } catch (error) {}
          formErrors[error.path] = error.message.replace(error.path, label);
        }
      });
    }
  }

  return {
    success,
    data: validData as InferType<S>,
    errors: formErrors,
  };
};

export default yupValidate;
