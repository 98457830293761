import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EditLocationIcon from '@mui/icons-material/EditLocationSharp';
import ForumIcon from '@mui/icons-material/Forum';
import GroupIcon from '@mui/icons-material/Group';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import PersonIcon from '@mui/icons-material/Person';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import SettingsIcon from '@mui/icons-material/Settings';
import TuneIcon from '@mui/icons-material/Tune';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import WorkIcon from '@mui/icons-material/Work';
import { ISideMenuItem } from 'src/interfaces/ISideMenu';
import { attendanceMenu } from 'src/modules/attendance/routes/menu';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';

const MenuItems: ISideMenuItem[] = [
  {
    primaryText: 'Dashboard',
    route: '/dashboard',
    icon: DashboardIcon,
  },
  {
    primaryText: 'Clientes',
    route: '/clientes',
    search: '?status=active',
    icon: PersonIcon,
    permissions: ['list-clients'],
  },
  {
    primaryText: 'Correntistas',
    route: '/correntistas',
    search: '?status=active',
    icon: AccountBalanceIcon,
    permissions: ['list-bank-accounts'],
  },
  {
    primaryText: 'Manutenção',
    icon: RoomPreferencesIcon,
    subItems: [
      {
        primaryText: 'Condomínios',
        route: '/imovel/condominios',
        permissions: [PROPERTY_PERMISSIONS.CONDOMINIUMS.LIST],
      },
      {
        primaryText: 'Imóveis',
        route: '/imovel/identidades',
        permissions: [PROPERTY_PERMISSIONS.PROPERTIES.LIST],
      },
      {
        primaryText: 'Ofertas',
        route: '/imovel/ofertas',
        permissions: [PROPERTY_PERMISSIONS.OFFERS.LIST],
      },
    ],
  },
  attendanceMenu,
  {
    primaryText: 'WhatsApp',
    icon: ForumIcon,
    route: '/omnichannel',
    subItems: [],
  },
  {
    primaryText: 'Parâmetros',
    icon: TuneIcon,
    subItems: [
      {
        primaryText: 'Estados Civis',
        route: '/estados-civis',
        search: '?status=active',
        icon: LabelOutlinedIcon,
        permissions: ['list-civil-status-types'],
      },
      {
        primaryText: 'Documentos',
        route: '/documentos',
        search: '?status=active',
        icon: LabelOutlinedIcon,
        permissions: ['list-document-types'],
      },
      {
        primaryText: 'Contatos',
        route: '/contatos',
        search: '?status=active',
        icon: LabelOutlinedIcon,
        permissions: ['list-contact-types'],
      },
      {
        primaryText: 'Bancos',
        route: '/bancos',
        search: '?status=active',
        icon: LabelOutlinedIcon,
        permissions: ['list-banks'],
      },
      {
        primaryText: 'Complementos',
        route: '/complementos',
        search: '?status=active',
        icon: LabelOutlinedIcon,
        permissions: ['list-address-complements'],
      },
      {
        primaryText: 'Tipo de Imóvel',
        route: '/tipos-imovel',
        search: '?status=active',
        icon: LabelOutlinedIcon,
        permissions: ['list-property-types'],
      },
      {
        primaryText: 'Detalhes de Condomínios',
        route: '/imovel/detalhes-condominios',
        search: '?status=active',
        icon: LabelOutlinedIcon,
        permissions: [PROPERTY_PERMISSIONS.DETAILS_CONDOMINIUMS.LIST],
      },
      {
        primaryText: 'Detalhes de Imóveis',
        route: '/imovel/detalhes-imoveis',
        search: '?status=active',
        icon: LabelOutlinedIcon,
        permissions: [PROPERTY_PERMISSIONS.DETAILS_PROPERTIES.LIST],
      },
      {
        primaryText: 'Tipos de Publicidades',
        route: '/imovel/tipos-publicidades',
        search: '?status=active',
        icon: LabelOutlinedIcon,
        permissions: [PROPERTY_PERMISSIONS.PUBLICITIES_TYPES.LIST],
      },
      {
        primaryText: 'Mídias',
        route: '/midias',
        search: '?status=active',
        icon: LabelOutlinedIcon,
        permissions: ['list-medias'],
      },
      {
        primaryText: 'Imobiliárias',
        route: '/imovel/imobiliarias',
        search: '?status=active',
        icon: LabelOutlinedIcon,
        permissions: [PROPERTY_PERMISSIONS.REALTORS.LIST],
      },
      {
        primaryText: 'Motivos de Ofertas',
        route: '/imovel/motivos-ofertas',
        search: '?status=active',
        icon: LabelOutlinedIcon,
        permissions: [PROPERTY_PERMISSIONS.OFFER_BROUGHT_VIAS.LIST],
      },
      {
        primaryText: 'Indicadores Econômicos',
        route: '/imovel/indicadores-economicos',
        search: '?status=active',
        icon: LabelOutlinedIcon,
        permissions: [PROPERTY_PERMISSIONS.ECONOMIC_INDICATORS.LIST],
      },
    ],
  },
  {
    primaryText: 'Configurações',
    icon: SettingsIcon,
    subItems: [
      {
        primaryText: 'Usuários',
        route: '/usuarios',
        search: '?status=active',
        icon: GroupIcon,
        permissions: ['list-users'],
      },
      {
        primaryText: 'Funções',
        route: '/funcoes',
        search: '?status=active',
        icon: WorkIcon,
        permissions: ['list-roles'],
      },
      {
        primaryText: 'Endereços',
        route: '/enderecos',
        search: '?status=active',
        icon: EditLocationIcon,
        permissions: [
          'list-addresses',
          'list-countries',
          'list-states',
          'list-cities',
          'list-neighborhoods',
          'list-streets',
        ],
      },
      {
        primaryText: 'Instâncias WhatsApp',
        route: '/instancias-whatsapp',
        search: '?status=active',
        icon: WhatsAppIcon,
        permissions: ['list-z-api-instances'],
      },
      {
        primaryText: 'Departamentos',
        route: '/departamentos',
        search: '?status=active',
        icon: LabelOutlinedIcon,
        permissions: ['list-departments'],
      },
    ],
  },
];

export default MenuItems;
