import yup from 'src/libs/yup';
import { operators } from 'src/utils/helpers';

const oneOfOpearators = operators
  .filter((op) => !!op.value)
  .map((op) => op.value);

export const defaultDateOperator = yup.object().shape({
  operator: yup
    .string()
    .transform((value) => (value !== '' ? value : null))
    .nullable()
    .oneOf([null, ...oneOfOpearators])
    .requiredWhen(yup.ref('value'), 'Data')
    .label('Operador'),
  value: yup
    .date()
    .nullable()
    .requiredWhen(yup.ref('operator'), 'Operador')
    .when('operator', {
      is: 'between',
      then: (schema) => schema.label('De'),
      otherwise: (schema) => schema.label('Data'),
    }),
  value_end: yup
    .date()
    .nullable()
    .when('operator', {
      is: 'between',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    })
    .label('Até'),
});

export const defaultNumberOperator = yup.object().shape({
  operator: yup
    .string()
    .transform((value) => (value !== '' ? value : null))
    .nullable()
    .oneOf([null, ...oneOfOpearators])
    .requiredWhen(yup.ref('value'), 'Data')
    .label('Operador'),
  value: yup
    .string()
    .requiredWhen(yup.ref('operator'), 'Operador')
    .when('operator', {
      is: 'between',
      then: (schema) => schema.label('De'),
      otherwise: (schema) => schema.label('Data'),
    }),
  value_end: yup
    .string()
    .when('operator', {
      is: 'between',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    })
    .label('Até'),
});

const DefaultFilterSchema = yup.object().shape({
  created_at: defaultDateOperator.label('Data de Criação'),
  updated_at: defaultDateOperator.label('Data de Alteração'),
  created_by: yup
    .mixed()
    .transform((value) => (value !== '' ? value : null))
    .nullable()
    .label('Criado Por'),
  updated_by: yup
    .mixed()
    .transform((value) => (value !== '' ? value : null))
    .nullable()
    .label('Alterado Por'),
  order_by: yup
    .array()
    .optional()
    .of(
      yup.object().shape({
        column: yup
          .string()
          .requiredWhen(yup.ref('direction'), 'Direção')
          .nullable()
          .label('Coluna'),
        direction: yup
          .string()
          .requiredWhen(yup.ref('column'), 'Coluna')
          .nullable()
          .oneOf([null, '', 'asc', 'desc'])
          .label('Direção'),
      }),
    )
    .label('Ordenar Por'),
});

export default DefaultFilterSchema;
