import styled from 'styled-components';

import MuiCheckIcon from '@mui/icons-material/Check';
import MuiCloseIcon from '@mui/icons-material/Close';
import MuiHelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MuiHourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import MuiLinkIcon from '@mui/icons-material/Link';
import MuiLockIcon from '@mui/icons-material/Lock';
import MuiPanToolIcon from '@mui/icons-material/PanTool';

export const HelpOutlineIcon = styled(MuiHelpOutlineIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const CheckIcon = styled(MuiCheckIcon)`
  color: ${({ theme }) => theme.palette.success.main};
`;

export const CloseIcon = styled(MuiCloseIcon)`
  color: ${({ theme }) => theme.palette.error.main};
`;

export const PendingIcon = styled(MuiHourglassEmptyIcon)`
  color: ${({ theme }) => theme.palette.warning.main};
`;

export const LinkIcon = styled(MuiLinkIcon)`
  color: ${({ theme }) => theme.palette.info.main};
`;

export const LockIcon = styled(MuiLockIcon)`
  color: ${({ theme }) => theme.palette.info.main};
`;

export const PanToolIcon = styled(MuiPanToolIcon)`
  color: ${({ theme }) => theme.palette.warning.main};
`;
