import { FC } from 'react';

import { Container } from './styles';

const Toast: FC = () => {
  return (
    <Container
      position="top-right"
      autoClose={5000}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

export default Toast;
