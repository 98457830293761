import dateFormat from 'date-fns/format';
import isValid from 'date-fns/isValid';
import localePtBR from 'date-fns/locale/pt-BR';
import parseISO from 'date-fns/parseISO';
import _ from 'lodash';
import QueryString, { ParsedQs, IParseOptions, IStringifyOptions } from 'qs';
import { store } from 'src/store';

export const queryToObject = (
  query: URLSearchParams | string,
  options?: Partial<IParseOptions & { decoder?: never }>,
): ParsedQs => {
  if (typeof query === 'string') query = new URLSearchParams(query);
  return QueryString.parse(query.toString(), {
    parseArrays: true,
    plainObjects: false,
    ...options,
  });
};

const removeEmpty = (param: any): Record<string, any> => {
  function internalClean(el: any) {
    return _.transform(el, (result: any, value, key) => {
      const isCollection = !_.isDate(value) && _.isObject(value);
      const cleaned = isCollection ? internalClean(value) : value;

      if (
        (isCollection && _.isEmpty(cleaned)) ||
        ['', null, undefined].includes(cleaned)
      ) {
        return;
      }

      _.isArray(result) ? result.push(cleaned) : (result[key] = cleaned);
    });
  }

  return _.isObject(param) ? internalClean(param) : param;
};

export const objectToQuery = (
  params: Record<string, any>,
  options?: Partial<IStringifyOptions>,
): string => {
  return QueryString.stringify(removeEmpty(params), {
    arrayFormat: 'indices',
    addQueryPrefix: true,
    encodeValuesOnly: true,
    ...options,
  });
};

export const operators = [
  { key: 1, label: 'Menor', value: '<' },
  { key: 2, label: 'Menor igual', value: '<=' },
  { key: 3, label: 'Igual', value: '=' },
  { key: 4, label: 'Maior igual', value: '>=' },
  { key: 5, label: 'Maior', value: '>' },
  { key: 6, label: 'Intervalo', value: 'between' },
];

export const orderDirections = [
  { key: 1, label: 'Crescente', value: 'asc' },
  { key: 2, label: 'Decrescente', value: 'desc' },
];

export const objectToFormData = (
  data: Record<string, any>,
  parentKey?: string,
  formData?: FormData,
): FormData => {
  if (!formData) formData = new FormData();

  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      objectToFormData(
        data[key],
        parentKey ? `${parentKey}[${key}]` : key,
        formData,
      );
    });
  } else {
    if (parentKey && data !== undefined) {
      if (data instanceof Date) {
        formData.append(parentKey, data.toJSON());
      } else {
        formData.append(parentKey, data === null ? '' : data);
      }
    }
  }

  return formData;
};

export const objectToUrlParams = (
  data: Record<string, any>,
  parentKey?: string,
  params?: URLSearchParams,
): URLSearchParams => {
  if (!params) params = new URLSearchParams();

  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      objectToUrlParams(
        data[key],
        parentKey ? `${parentKey}[${key}]` : key,
        params,
      );
    });
  } else {
    if (parentKey && !!data && !(data instanceof File)) {
      if (data instanceof Date) {
        params.append(parentKey, data.toJSON());
      } else {
        params.append(parentKey, data === null ? '' : data);
      }
    }
  }

  return params;
};

export const formatDate = (
  date: string | Date,
  format = 'dd/MM/yyyy',
): string => {
  if (typeof date === 'string') date = parseISO(date);
  if (!isValid(date)) return '';
  return dateFormat(date, format, { locale: localePtBR });
};

export const hasPermission = (allowedPermissions?: string[]): boolean => {
  const { user, permissions } = store.getState().auth;

  if (!user) return false;

  if (!allowedPermissions || allowedPermissions.length === 0 || user.is_admin)
    return true;

  const allowed = !!allowedPermissions?.some((allowedPerm) =>
    permissions?.find((perm) => perm.slug === allowedPerm),
  );

  return allowed;
};

export function formatToMoney(
  value: number,
  options?: Intl.NumberFormatOptions,
) {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    ...options,
  });

  return formatter.format(value);
}

/**
 *  use this to format 1000 -> R$ 10,00
 */
export function formatIntToMoney(
  value: number,
  options?: Intl.NumberFormatOptions,
) {
  return formatToMoney(value / 100, options);
}

export function formatToPercent(value: number) {
  return `${value}%`;
}

export function formatIntToPercent(value: number) {
  return `${value / 100}%`;
}

export function searchString(search: string, text: string) {
  search = search.normalize('NFD').replace(/\p{Diacritic}/gu, '');
  text = text.normalize('NFD').replace(/\p{Diacritic}/gu, '');

  return text.search(new RegExp(search, 'i')) !== -1;
}
