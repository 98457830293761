import React, { useEffect, useState } from 'react';

import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import List from '@mui/material/List';
import { ISideMenuItem } from 'src/interfaces/ISideMenu';
import apiAdminPublic from 'src/services/api/adminPublic';

import Item from './Item';
import MenuItems from './menu-items';

const MenuListItems: React.FC = () => {
  const [items, setItems] = useState(MenuItems);
  const { data: departmentsResponse } = apiAdminPublic.useDepartmentsQuery({
    requestConfig: { params: { has_z_api_instance: true } },
  });

  useEffect(() => {
    const whatsappSubItems = departmentsResponse?.data.map(
      (department) =>
        ({
          primaryText: department.name,
          route: `/omnichannel/${department.id}`,
          icon: LabelOutlinedIcon,
          permissions: ['list-omnichannel'],
        } as ISideMenuItem),
    );

    setItems((state) => {
      return state.map((s) => {
        if (s.route === '/omnichannel') {
          s.subItems = whatsappSubItems;
        }

        return s;
      });
    });
  }, [departmentsResponse?.data]);

  return (
    <List>
      {items.map((item) => (
        <Item key={item.primaryText} item={item} />
      ))}
    </List>
  );
};

export default MenuListItems;
