import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { COMMON_QUERY_KEYS } from 'src/constants/query';
import { IClient } from 'src/interfaces/models/IClient';
import { adminPublicAxios } from 'src/services/api/adminPublic';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IAxiosResponseAPI,
} from 'src/services/api/interfaces';

export async function getClients(
  config?: AxiosRequestConfig,
): Promise<IAxiosResponseAPI<IClient>> {
  const response = await adminPublicAxios.get('clients', config);

  return response;
}

export function useClientsQuery(data?: IApiUseQueryData<IClient>) {
  return useQuery<IApiUseQueryFnData<IClient>>(
    [COMMON_QUERY_KEYS.CLIENTS, data?.requestConfig?.params],
    () => getClients(data?.requestConfig),
    data?.queryOptions,
  );
}
