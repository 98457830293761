import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { IUser } from 'src/interfaces/models';
import {
  Funnel,
  FunnelStep,
  Opportunity,
  Reason,
} from 'src/modules/attendance/@types/models';
import { ATTENDANCE_COMMON_QUERY_KEYS } from 'src/modules/attendance/constants/query';
import apiAxios from 'src/services/api';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

/** REASONS */
export async function getReasons(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<Reason>>(
    '/attendance/admin/common/reasons',
    config,
  );

  return response;
}

export function useReasonsQuery(data?: IApiUseQueryData<Reason>) {
  return useQuery<IApiUseQueryFnData<Reason>>(
    [ATTENDANCE_COMMON_QUERY_KEYS.REASONS, data?.requestConfig?.params],
    () => getReasons(data?.requestConfig),
    data?.queryOptions,
  );
}

/** FUNNELS */
export async function getFunnels(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<Funnel>>(
    '/attendance/admin/common/funnels',
    config,
  );

  return response;
}

export function useFunnelsQuery(data?: IApiUseQueryData<Funnel>) {
  return useQuery<IApiUseQueryFnData<Funnel>>(
    [ATTENDANCE_COMMON_QUERY_KEYS.FUNNELS, data?.requestConfig?.params],
    () => getFunnels(data?.requestConfig),
    data?.queryOptions,
  );
}

/** FUNNELS */
export async function getFunnelSteps(
  funnelId: number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<IResponseAPIFilter<FunnelStep>>(
    `/attendance/admin/common/funnels/${funnelId}/steps`,
    config,
  );

  return response;
}

export function useFunnelStepsQuery(
  funnelId: number,
  data?: IApiUseQueryData<FunnelStep>,
) {
  return useQuery<IApiUseQueryFnData<FunnelStep>>(
    [
      ATTENDANCE_COMMON_QUERY_KEYS.FUNNELS_STEPS,
      funnelId,
      data?.requestConfig?.params,
    ],
    () => getFunnelSteps(funnelId, data?.requestConfig),
    data?.queryOptions,
  );
}

/** FUNNELS */
export async function getFunnelAttendants(
  funnelId: number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<IResponseAPIFilter<IUser>>(
    `/attendance/admin/common/funnels/${funnelId}/attendants`,
    config,
  );

  return response;
}

export function useFunnelAttendantsQuery(
  funnelId: number,
  data?: IApiUseQueryData<IUser>,
) {
  return useQuery<IApiUseQueryFnData<IUser>>(
    [
      ATTENDANCE_COMMON_QUERY_KEYS.FUNNELS_ATTENDANTS,
      funnelId,
      data?.requestConfig?.params,
    ],
    () => getFunnelAttendants(funnelId, data?.requestConfig),
    data?.queryOptions,
  );
}

/** CLIENT OPPORTUNITIES */
export async function getClientOpportunities(
  clientId: number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<IResponseAPIFilter<Opportunity>>(
    `/attendance/admin/common/clients/${clientId}/opportunities`,
    config,
  );

  return response;
}

export function useClientOpportunitiesQuery(
  clientId: number,
  data?: IApiUseQueryData<Opportunity>,
) {
  return useQuery<IApiUseQueryFnData<Opportunity>>(
    [
      ATTENDANCE_COMMON_QUERY_KEYS.CLIENTS_OPPORTUNITIES,
      data?.requestConfig?.params,
    ],
    () => getClientOpportunities(clientId, data?.requestConfig),
    data?.queryOptions,
  );
}
