import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Can from 'src/components/Can';
import ListItemCollapse from 'src/components/ListItemCollapse';
import { ISideMenuItem } from 'src/interfaces/ISideMenu';

// import { Container } from './styles';

interface IItemProps {
  item: ISideMenuItem;
}

const Item: React.FC<IItemProps> = ({ item }) => {
  if (item.subItems) {
    return (
      <ListItemCollapse
        primary={item.primaryText}
        icon={item.icon && <item.icon />}
      >
        {item.subItems.map((subItem) => (
          <Item key={subItem.primaryText} item={subItem} />
        ))}
      </ListItemCollapse>
    );
  }

  const renderItem = () => (
    <ListItem
      button
      component={(props) => (
        <RouterLink
          {...props}
          to={{ pathname: item.route, search: item.search }}
        />
      )}
    >
      {item.icon && (
        <ListItemIcon>
          <item.icon />
        </ListItemIcon>
      )}
      <ListItemText primary={item.primaryText} secondary={item.secondaryText} />
    </ListItem>
  );

  if (item.permissions) {
    return (
      <Can key={item.primaryText} permissions={item.permissions}>
        {renderItem()}
      </Can>
    );
  }

  return renderItem();
};

export default Item;
