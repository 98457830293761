import { FC, useContext } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { Typography } from '@mui/material';
import Package from 'src/../package.json';
import UserMenu from 'src/routes/Private/Layout/TopNavigation/UserMenu';
import PrivateContext from 'src/routes/Private/PrivateContext';

import { AppBar, Toolbar, MenuButton, Title } from './styles';

const TopNavigation: FC = () => {
  const { sideMenuIsOpen, openSideMenu } = useContext(PrivateContext);

  return (
    <AppBar position="absolute" isOpen={sideMenuIsOpen}>
      <Toolbar>
        <MenuButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={openSideMenu}
          isOpen={sideMenuIsOpen}
        >
          <MenuIcon />
        </MenuButton>

        <Title variant="h6" color="inherit" noWrap>
          {process.env.REACT_APP_TITLE_PREFIX}{' '}
          <Typography variant="caption" color="inherit">
            v{Package.version}
          </Typography>
        </Title>

        <UserMenu />
      </Toolbar>
    </AppBar>
  );
};

export default TopNavigation;
