import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { AttendanceReducerState, reducers } from './ducks/reducer';

const persistKey = process.env.REACT_APP_PERSISTOR_KEY || 'template-react-ts';
const persistConfig: PersistConfig<AttendanceReducerState> = {
  key: `${persistKey}-attendance`,
  storage,
  whitelist: ['funnel'],
};

const attendanceReducer = persistReducer(persistConfig, reducers);

export default attendanceReducer;
