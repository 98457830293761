import { FC } from 'react';

export interface ITabPanelProps {
  // value: number | null;
  // index: number;
  show: boolean;
}

const TabPanel: FC<ITabPanelProps> = ({ show, children }) => {
  return (
    <div role="tabpanel" hidden={!show}>
      {show && children}
    </div>
  );
};

export default TabPanel;
