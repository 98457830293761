export const STATUSES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PENDING: 'pending',
  CANCELED: 'canceled',
};

export const CHAT_STATUS_LABEL = {
  active: 'Aberto',
  inactive: 'Encerrado',
};

export const INPUT_TYPE_LABEL = {
  boolean: 'Verdadeiro/Falso',
  number: 'Número',
  text: 'Texto',
};

export const INPUT_TYPE_OPTIONS = [
  {
    key: 0,
    label: INPUT_TYPE_LABEL['boolean'],
    value: 'boolean',
  },
  {
    key: 1,
    label: INPUT_TYPE_LABEL['number'],
    value: 'number',
  },
  {
    key: 2,
    label: INPUT_TYPE_LABEL['text'],
    value: 'text',
  },
];

export const MEDIA_TYPE_LABEL = {
  property_photo: 'Foto de Imóvel',
  property_video: 'Vídeo de Imóvel',
  property_file: 'Arquivo de Imóvel',
};

export const MEDIA_TYPE_OPTIONS = [
  {
    key: 0,
    label: MEDIA_TYPE_LABEL['property_photo'],
    value: 'property_photo',
  },
  {
    key: 1,
    label: MEDIA_TYPE_LABEL['property_video'],
    value: 'property_video',
  },
  {
    key: 2,
    label: MEDIA_TYPE_LABEL['property_file'],
    value: 'property_file',
  },
];

export const MEDIA_SOURCE_LABEL = {
  local: 'Upload Local',
  external_link: 'Link Externo',
};

export const MEDIA_SOURCE_OPTIONS = [
  {
    key: 0,
    label: MEDIA_SOURCE_LABEL['local'],
    value: 'local',
  },
  {
    key: 1,
    label: MEDIA_SOURCE_LABEL['external_link'],
    value: 'external_link',
  },
];

export const Z_API_INSTANCE_STATUS_LABEL = {
  connected: 'Conectado',
  disconnected: 'Desconectado',
};

export const Z_API_INSTANCE_STATUS_OPTIONS = Object.entries(
  Z_API_INSTANCE_STATUS_LABEL,
).map(([key, value], index) => ({
  key: index,
  label: value,
  value: key,
}));
