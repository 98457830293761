import axios from 'axios';
import { store } from 'src/store';

import * as addresses from './addresses';
import * as clients from './clients';
import * as dashboard from './dashboard';
import * as departments from './departments';
import * as permissions from './permissions';
import * as roles from './roles';
import * as types from './types';
import * as users from './users';
import * as zApiInstances from './zApiInstances';

export const adminPublicAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/admin-public`,
  headers: {
    Accept: 'application/json',
  },
});

adminPublicAxios.interceptors.request.use((config) => {
  const token = store.getState().auth.token;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

const apiAdminPublic = {
  ...addresses,
  ...types,
  ...clients,
  ...permissions,
  ...roles,
  ...users,
  ...zApiInstances,
  ...departments,
  ...dashboard,
};

export default apiAdminPublic;
