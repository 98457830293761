import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { FunnelStep } from 'src/modules/attendance/@types/models';
import {
  ATTENDANCE_ADMIN_QUERY_KEYS,
  ATTENDANCE_COMMON_QUERY_KEYS,
} from 'src/modules/attendance/constants/query';
import apiAxios from 'src/services/api';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

async function getFunnelSteps(
  funnelId: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<IResponseAPIFilter<FunnelStep>>(
    `/attendance/admin/funnels/${funnelId}/steps`,
    config,
  );

  return response;
}

function useFunnelStepsQuery(
  funnelId: string | number,
  data?: IApiUseQueryData<FunnelStep>,
) {
  return useQuery<IApiUseQueryFnData<FunnelStep>>(
    [
      ATTENDANCE_ADMIN_QUERY_KEYS.FUNNELS_STEPS,
      funnelId,
      data?.requestConfig?.params,
    ],
    () => getFunnelSteps(funnelId, data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateFunnelStepsQueries() {
  queryClient.invalidateQueries([ATTENDANCE_COMMON_QUERY_KEYS.FUNNELS_STEPS]);
  queryClient.invalidateQueries([ATTENDANCE_ADMIN_QUERY_KEYS.FUNNELS_STEPS]);
}

async function storeFunnelStep(
  funnelId: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<FunnelStep>(
    `/attendance/admin/funnels/${funnelId}/steps`,
    data,
    config,
  );

  invalidateFunnelStepsQueries();

  return response;
}

async function getFunnelStep(
  funnelId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<FunnelStep>(
    `/attendance/admin/funnels/${funnelId}/steps/${id}`,
    config,
  );

  return response;
}

async function updateFunnelStep(
  funnelId: string | number,
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<FunnelStep>(
    `/attendance/admin/funnels/${funnelId}/steps/${id}`,
    data,
    config,
  );

  invalidateFunnelStepsQueries();

  return response;
}

async function destroyFunnelStep(
  funnelId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(
    `/attendance/admin/funnels/${funnelId}/steps/${id}`,
    config,
  );

  invalidateFunnelStepsQueries();

  return response;
}

async function restoreFunnelStep(
  funnelId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/attendance/admin/funnels/${funnelId}/steps/${id}/restore`,
    config,
  );

  invalidateFunnelStepsQueries();

  return response;
}

export const steps = {
  getFunnelSteps,
  useFunnelStepsQuery,
  storeFunnelStep,
  getFunnelStep,
  updateFunnelStep,
  destroyFunnelStep,
  restoreFunnelStep,
};
