import { FC, useCallback, useRef } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import FilterDrawer from 'src/components/FilterDrawer';
import { FilterDrawerRef } from 'src/components/FilterDrawer/interfaces';

import CustomToolbar from './CustomToolbar';
import { TableProps } from './interfaces';

const Table: FC<TableProps> = ({
  columns,
  showFilters,
  filterProps,
  ...rest
}) => {
  const filterRef = useRef<FilterDrawerRef>(null);

  const onFilterClick = useCallback(() => {
    filterRef.current?.open();
  }, []);

  return (
    <>
      {showFilters && (
        <FilterDrawer
          columns={columns.filter((column) => !column.hide && column.field)}
          {...filterProps}
          ref={filterRef}
        />
      )}

      <DataGrid
        components={{ Toolbar: CustomToolbar }}
        componentsProps={{ toolbar: { showFilters, onFilterClick } }}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        columns={columns.map((column) => ({
          ...column,
          sortable: false,
        }))}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        {...rest}
      />
    </>
  );
};

export default Table;
