export const COMMON_QUERY_KEYS = {
  PERMISSIONS: 'common-permissions',
  ROLES: 'common-roles',
  USERS: 'common-users',
  DASHBOARD: 'common-dashboard',
  CLIENTS: 'common-clients',
  NEIGHBORHOODS: 'common-neighborhoods',
  STREETS: 'common-streets',
  PROPERTY_TYPES: 'common-property-types',
  MEDIAS: 'common-medias',
  Z_API_INSTANCES: 'common-z-api-intances',
  DEPARTMENTS: 'common-departments',
};

export const ADMIN_QUERY_KEYS = {
  PERMISSIONS: 'admin-permissions',
  ROLES: 'admin-roles',
  USERS: 'admin-users',
  CLIENTS: 'admin-clients',
  MEDIAS: 'admin-medias',
  Z_API_INSTANCES: 'admin-z-api-intances',
  DEPARTMENTS: 'admin-departments',
};
