import { sub } from 'date-fns';
import { createActions, createReducer } from 'reduxsauce';
import { AuthTypes } from 'src/store/ducks/auth';

import {
  IDashboardCreators,
  IDashboardSetFilters,
  IDashboardState,
  IDashboardTypes,
} from './types';

/* Types & Actions Creators */
export const {
  Types: DashboardTypes,
  Creators: DashboardActions,
} = createActions<IDashboardTypes, IDashboardCreators>({
  dashboardSetFilters: ['filters'],
  dashboardCleanFilters: [],
});

/* Initial State */
const INITIAL_STATE: IDashboardState = {
  filters: {
    period: {
      operator: 'between',
      value: sub(new Date(), { days: 7 }).toJSON(),
      value_end: new Date().toJSON(),
    },
  },
};

/* Reducers */
const dashboardSetFilters = (
  state: IDashboardState,
  { filters }: IDashboardSetFilters,
) => ({
  ...state,
  filters,
});

const dashboardCleanFilters = (state: IDashboardState) => ({
  ...state,
  filters: {},
});

/* Reducers to types */
const reducer = createReducer(INITIAL_STATE, {
  [DashboardTypes.DASHBOARD_SET_FILTERS]: dashboardSetFilters,
  [DashboardTypes.DASHBOARD_CLEAN_FILTERS]: dashboardCleanFilters,
  [AuthTypes.AUTH_LOGOUT]: dashboardCleanFilters,
});

export default reducer;
