import { BaseSchema } from 'yup';
import Reference from 'yup/lib/Reference';

export default function equalTo(
  this: BaseSchema,
  ref: Reference<any>,
  refLabel?: string,
): any {
  return this.test({
    name: 'equal',
    exclusive: false,
    message: '${path} precisa ser igual à ${reference}',
    params: {
      reference: refLabel || ref.path,
    },
    test: function (value: any) {
      return value === this.resolve(ref);
    },
  });
}
