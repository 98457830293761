import styled, { css } from 'styled-components';

import { TextField as MuiTextField } from '@mui/material';

export const Container = styled(MuiTextField)`
  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`;
