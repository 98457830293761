import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

const BackButton: FC<IconButtonProps> = (props) => {
  const history = useHistory();

  return (
    <IconButton size="small" onClick={() => history.goBack()} {...props}>
      <ArrowBackIcon />
    </IconButton>
  );
};

export default BackButton;
