import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { COMMON_QUERY_KEYS } from 'src/constants/query';
import { ICivilStatusType } from 'src/interfaces/models/IClient';
import IContactType from 'src/interfaces/models/IContactType';
import IDocumentType from 'src/interfaces/models/IDocumentType';
import IMedia from 'src/interfaces/models/IMedia';
import IPropertyType from 'src/interfaces/models/IPropertyType';
import { adminPublicAxios } from 'src/services/api/adminPublic';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IAxiosResponseAPI,
} from 'src/services/api/interfaces';

export async function getCivilStatusTypes(
  config?: AxiosRequestConfig,
): Promise<IAxiosResponseAPI<ICivilStatusType>> {
  const response = await adminPublicAxios.get('civil-status-types', config);

  return response;
}

export async function getPropertyTypes(
  config?: AxiosRequestConfig,
): Promise<IAxiosResponseAPI<IPropertyType>> {
  const response = await adminPublicAxios.get('property-types', config);

  return response;
}

export function usePropertyTypesQuery(data?: IApiUseQueryData<IPropertyType>) {
  return useQuery<IApiUseQueryFnData<IPropertyType>>(
    [COMMON_QUERY_KEYS.PROPERTY_TYPES, data?.requestConfig?.params],
    () => getPropertyTypes(data?.requestConfig),
    data?.queryOptions,
  );
}

export async function getDocumentTypes(
  config?: AxiosRequestConfig,
): Promise<IAxiosResponseAPI<IDocumentType>> {
  const response = await adminPublicAxios.get('document-types', config);

  return response;
}

export async function getContactTypes(
  config?: AxiosRequestConfig,
): Promise<IAxiosResponseAPI<IContactType>> {
  const response = await adminPublicAxios.get('contact-types', config);

  return response;
}

export async function getMedias(
  config?: AxiosRequestConfig,
): Promise<IAxiosResponseAPI<IMedia>> {
  const response = await adminPublicAxios.get('medias', config);

  return response;
}

export function useMediasQuery(data?: IApiUseQueryData<IMedia>) {
  return useQuery<IApiUseQueryFnData<IMedia>>(
    [COMMON_QUERY_KEYS.MEDIAS, data?.requestConfig?.params],
    () => getMedias(data?.requestConfig),
    data?.queryOptions,
  );
}
