import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { IPermission } from 'src/interfaces/models';
import { adminPublicAxios } from 'src/services/api/adminPublic';

export async function getPermissions(
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<IPermission[]>> {
  const response = await adminPublicAxios.get<IPermission[]>(
    'permissions',
    config,
  );

  return response;
}
