import { createActions, createReducer } from 'reduxsauce';

import {
  IAuthTypes,
  IAuthCreators,
  IAuthState,
  IAuthSuccessAction,
  IAuthProfileAction,
} from './types';

/* Types & Actions Creators */
export const { Types: AuthTypes, Creators: AuthActions } = createActions<
  IAuthTypes,
  IAuthCreators
>({
  authFailure: [],
  authSuccess: ['token', 'user', 'permissions'],
  authProfile: ['user', 'permissions'],
  authLogout: [],
});

/* Initial State */
const INITIAL_STATE: IAuthState = {
  isAuthenticated: false,
  loading: false,
  token: undefined,
  user: undefined,
  permissions: undefined,
};

/* Reducers */
const authFailure = () => INITIAL_STATE;

const authSuccess = (
  state: IAuthState,
  { token, user, permissions }: IAuthSuccessAction,
) => ({
  ...INITIAL_STATE,
  isAuthenticated: true,
  token,
  user,
  permissions,
});

const authProfile = (
  state: IAuthState,
  { user, permissions }: IAuthProfileAction,
) => ({
  ...state,
  user,
  permissions,
});

const authLogout = () => INITIAL_STATE;

/* Reducers to types */
const reducer = createReducer(INITIAL_STATE, {
  [AuthTypes.AUTH_FAILURE]: authFailure,
  [AuthTypes.AUTH_SUCCESS]: authSuccess,
  [AuthTypes.AUTH_PROFILE]: authProfile,
  [AuthTypes.AUTH_LOGOUT]: authLogout,
});

export default reducer;
