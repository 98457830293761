import { FC } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import Can from 'src/components/Can';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import TableActions from 'src/components/Table/Actions';
import StatusIcon from 'src/components/Table/StatusIcon';
import useApiQueryPagination from 'src/hooks/useApiQueryPagination';
import { Funnel } from 'src/modules/attendance/@types/models';
import { ATTENDANCE_PERMISSIONS } from 'src/modules/attendance/constants/permissions';
import attendanceApi from 'src/modules/attendance/services/api';
import FunnelFilterSchema from 'src/modules/attendance/validators/Funnel/filter.schema';

import FunnelFilters from './FunnelFilters';
import { Container } from './styles';

const List: FC = () => {
  const {
    handlePageChange,
    handlePageSizeChange,
    isLoading,
    data: response,
    refetch,
  } = useApiQueryPagination(attendanceApi.admin.funnels.useFunnelsQuery);

  const columns: GridColDef<Funnel>[] = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Nome', flex: 1 },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return <StatusIcon status={params.row.status} />;
      },
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      renderCell({ id, row }) {
        return (
          <TableActions
            resourceName="Funil"
            resourceId={id}
            resourceStatus={row.status}
            editLink={`/atendimento/funis/${id}`}
            editPermissions={[ATTENDANCE_PERMISSIONS.FUNNELS.UPDATE]}
            destroyApiEndpoint={attendanceApi.admin.funnels.destroyFunnel}
            destroyPermissions={[ATTENDANCE_PERMISSIONS.FUNNELS.DESTROY]}
            restoreApiEndpoint={attendanceApi.admin.funnels.restoreFunnel}
            restorePermissions={[ATTENDANCE_PERMISSIONS.FUNNELS.RESTORE]}
            descriptionPrefix={`O funil ${row.name}`}
            onFinish={refetch}
          />
        );
      },
    },
  ];

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Funis</Typography>

            <Can
              behavior="disable"
              permissions={[ATTENDANCE_PERMISSIONS.FUNNELS.STORE]}
            >
              <LinkButton
                to="/atendimento/funis/novo"
                startIcon={<AddIcon />}
                color="primary"
                variant="contained"
                size="small"
              >
                Novo
              </LinkButton>
            </Can>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={response?.data.data || []}
                pageSize={response?.data.meta.per_page}
                rowCount={response?.data.meta.total}
                columns={columns}
                loading={isLoading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                canSearch
                showFilters
                filterProps={{
                  yupSchema: FunnelFilterSchema,
                  children: <FunnelFilters />,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default List;
