import { FC, Children, cloneElement, isValidElement } from 'react';

import { hasPermission } from 'src/utils/helpers';

export type CanProps = {
  behavior?: 'hide' | 'disable';
  permissions: string[];
};

const Can: FC<CanProps> = ({ behavior, permissions, children }) => {
  if (hasPermission(permissions)) {
    return <>{children}</>;
  }

  if (behavior === 'disable') {
    return (
      <>
        {Children.map(children, (child) =>
          isValidElement(child)
            ? cloneElement<any>(child, { disabled: true })
            : null,
        )}
      </>
    );
  }

  return null;
};

Can.defaultProps = {
  behavior: 'disable',
};

export default Can;
