import { combineReducers } from 'redux';
import attendance from 'src/modules/attendance/store';

import auth from './auth';
import dashboard from './dashboard';

const reducers = combineReducers({
  auth,
  attendance,
  dashboard,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
