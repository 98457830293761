import { FC, useCallback, useState } from 'react';
import Helmet from 'react-helmet';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';

import { IRouteInterface } from 'src/interfaces/IRoutes';
import { hasPermission } from 'src/utils/helpers';

import PrivateLayout from './Layout';
import PrivateContext from './PrivateContext';

const Private: FC<IRouteInterface> = ({
  component: Component,
  title,
  permissions,
  ...rest
}) => {
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);
  const [layoutLoading, SetLayoutLoading] = useState(false);

  const openSideMenu = useCallback(() => {
    setSideMenuIsOpen(true);
  }, []);
  const closeSideMenu = useCallback(() => {
    setSideMenuIsOpen(false);
  }, []);

  const startLayoutLoading = useCallback(() => {
    SetLayoutLoading(true);
  }, []);
  const stopLayoutLoading = useCallback(() => {
    SetLayoutLoading(false);
  }, []);

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) => {
        if (!hasPermission(permissions)) {
          toast.warn('Ops, você não tem permissão para acessar essa página!');

          return (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          );
        }

        return (
          <>
            <Helmet>
              <title>{title}</title>
            </Helmet>
            <PrivateContext.Provider
              value={{
                sideMenuIsOpen,
                openSideMenu,
                closeSideMenu,
                layoutLoading,
                startLayoutLoading,
                stopLayoutLoading,
              }}
            >
              <PrivateLayout>
                <Component {...props} />
              </PrivateLayout>
            </PrivateContext.Provider>
          </>
        );
      }}
    />
  );
};

export default Private;
