import ThreePIcon from '@mui/icons-material/ThreeP';
import { ISideMenuItem } from 'src/interfaces/ISideMenu';
import { ATTENDANCE_PERMISSIONS } from 'src/modules/attendance/constants/permissions';

export const attendanceMenu: ISideMenuItem = {
  primaryText: 'Atendimento',
  icon: ThreePIcon,
  subItems: [
    {
      primaryText: 'Oportunidades',
      route: '/atendimento/oportunidades',
      search: 'status=open',
    },
    {
      primaryText: 'Configurações',
      subItems: [
        {
          primaryText: 'Motivos',
          permissions: [ATTENDANCE_PERMISSIONS.REASONS.LIST],
          route: '/atendimento/motivos',
        },
        {
          primaryText: 'Funis',
          permissions: [ATTENDANCE_PERMISSIONS.FUNNELS.LIST],
          route: '/atendimento/funis',
        },
      ],
    },
  ],
};
