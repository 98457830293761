import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { COMMON_QUERY_KEYS } from 'src/constants/query';
import { IUser } from 'src/interfaces/models';
import { adminPublicAxios } from 'src/services/api/adminPublic';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IAxiosResponseAPI,
} from 'src/services/api/interfaces';

export async function getUsers(
  config?: AxiosRequestConfig,
): Promise<IAxiosResponseAPI<IUser>> {
  const response = await adminPublicAxios.get('users', config);

  return response;
}

export function useUsersQuery(data?: IApiUseQueryData<IUser>) {
  return useQuery<IApiUseQueryFnData<IUser>>(
    [COMMON_QUERY_KEYS.USERS, data?.requestConfig?.params],
    () => getUsers(data?.requestConfig),
    data?.queryOptions,
  );
}

export async function getUser(
  id: string | number,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<IUser>> {
  const response = await adminPublicAxios.get(`users/${id}`, config);

  return response;
}

export function useUserQuery(
  id: string | number,
  data?: IApiUseQueryData<IUser, AxiosResponse>,
) {
  return useQuery<AxiosResponse<IUser>>(
    [COMMON_QUERY_KEYS.USERS, id, data?.requestConfig?.params],
    () => getUser(id, data?.requestConfig),
    data?.queryOptions,
  );
}
