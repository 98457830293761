interface NotTypeParams {
  path: string;
  type: string;
}

const typeNames: Record<string, string> = {
  mixed: 'qualquer',
  string: 'texto',
  number: 'número',
  array: 'lista',
  date: 'data',
  object: 'objeto',
  boolean: 'boleano',
};

export default {
  mixed: {
    default: '${path} é inválido',
    required: '${path} é um campo obrigatório',
    oneOf: '${path} deve ser um dos seguintes valores: ${values}',
    notOneOf: '${path} não pode ser um dos seguintes valores: ${values}',
    defined: '${path} precisar estar definido',
    notType: ({ path, type }: NotTypeParams): string =>
      `${path} precisa ser do tipo "${typeNames[type]}"`,
  },
  string: {
    length: '${path} deve ter exatamente ${length} caracteres',
    min: '${path} deve ter pelo menos ${min} caracteres',
    max: '${path} deve ter no máximo ${max} caracteres',
    matches: '${path} precisa seguir o seguinte formato: "${regex}"',
    email: '${path} tem o formato de e-mail inválido',
    url: '${path} deve ter um formato de URL válida',
    uuid: '${path} precisa ser um UUID válido',
    trim: '${path} não deve conter espaços no início ou no fim.',
    lowercase: '${path} deve estar em maiúsculo',
    uppercase: '${path} deve estar em minúsculo',
  },
  number: {
    min: '${path} deve ser no mínimo ${min}',
    max: '${path} deve ser no máximo ${max}',
    lessThan: '${path} deve ser menor que ${less}',
    moreThan: '${path} deve ser maior que ${more}',
    notEqual: '${path} não pode ser igual à ${notEqual}',
    positive: '${path} deve ser um número positivo',
    negative: '${path} deve ser um número negativo',
    integer: '${path} deve ser um número inteiro',
  },
  date: {
    min: '${path} deve ser maior que a data ${min}',
    max: '${path} deve ser menor que a data ${max}',
  },
  array: {
    min: '${path} deve ter no mínimo ${min} item(s)',
    max: '${path} deve ter no máximo ${max} item(s)',
    length: '${path} precisa ter ${length} itens',
  },
  object: {
    noUnknown: '${path} precisa dos campos específicos: ${unknown}',
  },
  boolean: {
    isValue: '${path} precisa ser ${value}',
  },
};
