import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { COMMON_QUERY_KEYS } from 'src/constants/query';
import IDepartment from 'src/interfaces/models/IDepartment';
import { adminPublicAxios } from 'src/services/api/adminPublic';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IAxiosResponseAPI,
} from 'src/services/api/interfaces';

export async function getDepartments(
  config?: AxiosRequestConfig,
): Promise<IAxiosResponseAPI<IDepartment>> {
  const response = await adminPublicAxios.get('departments', config);

  return response;
}

export function useDepartmentsQuery(data?: IApiUseQueryData<IDepartment>) {
  return useQuery<IApiUseQueryFnData<IDepartment>>(
    [COMMON_QUERY_KEYS.DEPARTMENTS, data?.requestConfig?.params],
    () => getDepartments(data?.requestConfig),
    data?.queryOptions,
  );
}

export async function getDepartment(
  id: number | string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<IDepartment>> {
  const response = await adminPublicAxios.get(`departments/${id}`, config);

  return response;
}
