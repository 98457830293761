import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { Funnel } from 'src/modules/attendance/@types/models';
import {
  ATTENDANCE_ADMIN_QUERY_KEYS,
  ATTENDANCE_COMMON_QUERY_KEYS,
} from 'src/modules/attendance/constants/query';
import apiAxios from 'src/services/api';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

export async function getFunnels(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<Funnel>>(
    '/attendance/admin/funnels',
    config,
  );

  return response;
}

export function useFunnelsQuery(data?: IApiUseQueryData<Funnel>) {
  return useQuery<IApiUseQueryFnData<Funnel>>(
    [ATTENDANCE_ADMIN_QUERY_KEYS.FUNNELS, data?.requestConfig?.params],
    () => getFunnels(data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateFunnelsQueries() {
  queryClient.invalidateQueries([ATTENDANCE_COMMON_QUERY_KEYS.FUNNELS]);
  queryClient.invalidateQueries([
    ATTENDANCE_COMMON_QUERY_KEYS.FUNNELS_ATTENDANTS,
  ]);
  queryClient.invalidateQueries([ATTENDANCE_ADMIN_QUERY_KEYS.FUNNELS]);
}

export async function storeFunnel(
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<Funnel>(
    '/attendance/admin/funnels',
    data,
    config,
  );

  invalidateFunnelsQueries();

  return response;
}

export async function getFunnel(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<Funnel>(
    `/attendance/admin/funnels/${id}`,
    config,
  );

  return response;
}

export async function updateFunnel(
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<Funnel>(
    `/attendance/admin/funnels/${id}`,
    data,
    config,
  );

  invalidateFunnelsQueries();

  return response;
}

export async function destroyFunnel(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(
    `/attendance/admin/funnels/${id}`,
    config,
  );

  invalidateFunnelsQueries();

  return response;
}

export async function restoreFunnel(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/attendance/admin/funnels/${id}/restore`,
    config,
  );

  invalidateFunnelsQueries();

  return response;
}

export { attendants } from './attendants';
export { steps } from './steps';
export { opportunities } from './opportunities';
export { unreadChats } from './unreadChats';
