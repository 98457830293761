import { UseQueryResult } from '@tanstack/react-query';
import { TableProps } from 'src/components/Table/interfaces';
import {
  IApiUseQueryFnData,
  IResponseAPIPaginationMeta,
} from 'src/services/api/interfaces';

export type IUseApiQueryPaginationReturn<T> = {
  handlePageChange: TableProps['onPageChange'];
  handlePageSizeChange: TableProps['onPageSizeChange'];
} & UseQueryResult<IApiUseQueryFnData<T>>;

export const INITIAL_INFO: IResponseAPIPaginationMeta = {
  current_page: 1,
  first_page: 1,
  last_page: 1,
  per_page: 10,
  total: 0,
};
