import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { COMMON_QUERY_KEYS } from 'src/constants/query';
import { Dashboard } from 'src/interfaces/models/Dashboard';
import { adminPublicAxios } from 'src/services/api/adminPublic';
import { IApiUseQueryData } from 'src/services/api/interfaces';

export async function getDashboard(config?: AxiosRequestConfig) {
  const response = await adminPublicAxios.get<Dashboard>('dashboard', config);

  return response;
}

export function useDashboardQuery(
  data?: IApiUseQueryData<Dashboard, AxiosResponse>,
) {
  return useQuery<AxiosResponse<Dashboard>>(
    [COMMON_QUERY_KEYS.DASHBOARD, data?.requestConfig?.params],
    () => getDashboard(data?.requestConfig),
    data?.queryOptions,
  );
}
