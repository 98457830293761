import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ATTENDANCE_ADMIN_QUERY_KEYS } from 'src/modules/attendance/constants/query';
import apiAxios from 'src/services/api';
import { IApiUseQueryData } from 'src/services/api/interfaces';
import { Chat } from 'src/services/websocket/omnichannel/interfaces';

async function getUnreadChats(
  funnelId: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<Chat[]>(
    `/attendance/admin/funnels/${funnelId}/unread-chats`,
    config,
  );

  return response;
}

function useUnreadChatsQuery(
  funnelId: string | number,
  data?: IApiUseQueryData<Chat, AxiosResponse>,
) {
  return useQuery<AxiosResponse<Chat[]>>(
    [
      ATTENDANCE_ADMIN_QUERY_KEYS.FUNNELS,
      funnelId,
      'unread-chats',
      data?.requestConfig?.params,
    ],
    () => getUnreadChats(funnelId, data?.requestConfig),
    data?.queryOptions,
  );
}

export const unreadChats = {
  getUnreadChats,
  useUnreadChatsQuery,
};
