import { AxiosRequestConfig } from 'axios';
import { IRole } from 'src/interfaces/models';
import { adminPublicAxios } from 'src/services/api/adminPublic';
import { IAxiosResponseAPI } from 'src/services/api/interfaces';

export async function getRoles(
  config?: AxiosRequestConfig,
): Promise<IAxiosResponseAPI<IRole>> {
  const response = await adminPublicAxios.get('roles', config);

  return response;
}
