import { cpf, cnpj } from 'cpf-cnpj-validator';
import { StringSchema } from 'yup';

export function validateCpf(this: StringSchema, msg?: string): any {
  return this.test({
    name: 'cpf',
    exclusive: false,
    message: msg || '${path} não possui um formato válido',
    test(value) {
      return cpf.isValid(value || '');
    },
  });
}

export function validateCnpj(this: StringSchema, msg: string): any {
  return this.test({
    name: 'cpf',
    exclusive: false,
    message: msg || '${path} não possui um formato válido',
    test(value) {
      return cnpj.isValid(value || '');
    },
  });
}

export function validateCpfCnpj(this: StringSchema, msg: string): any {
  return this.test({
    name: 'cpf',
    exclusive: false,
    message: msg || '${path} não possui um formato válido',
    test(value) {
      const length = value ? value.length : 0;
      if (length > 11) {
        return cnpj.isValid(value || '');
      } else {
        return cpf.isValid(value || '');
      }
    },
  });
}
