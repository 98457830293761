export const PROPERTY_PERMISSIONS = {
  CONDOMINIUMS: {
    LIST: 'list-condominiums',
    STORE: 'store-condominiums',
    UPDATE: 'update-condominiums',
    DESTROY: 'destroy-condominiums',
    RESTORE: 'restore-condominiums',
  },
  DETAILS_CONDOMINIUMS: {
    LIST: 'list-details-condominiums',
    STORE: 'store-details-condominiums',
    UPDATE: 'update-details-condominiums',
    DESTROY: 'destroy-details-condominiums',
    RESTORE: 'restore-details-condominiums',
  },
  PUBLICITIES_TYPES: {
    LIST: 'list-publicities-types',
    STORE: 'store-publicities-types',
    UPDATE: 'update-publicities-types',
    DESTROY: 'destroy-publicities-types',
    RESTORE: 'restore-publicities-types',
  },
  DETAILS_PROPERTIES: {
    LIST: 'list-details-properties',
    STORE: 'store-details-properties',
    UPDATE: 'update-details-properties',
    DESTROY: 'destroy-details-properties',
    RESTORE: 'restore-details-properties',
  },
  PROPERTIES: {
    LIST: 'list-properties',
    STORE: 'store-properties',
    UPDATE: 'update-properties',
    DESTROY: 'destroy-properties',
    RESTORE: 'restore-properties',
  },
  REALTORS: {
    LIST: 'list-realtors',
    STORE: 'store-realtors',
    UPDATE: 'update-realtors',
    DESTROY: 'destroy-realtors',
    RESTORE: 'restore-realtors',
  },
  ECONOMIC_INDICATORS: {
    LIST: 'list-economic-indicators',
    STORE: 'store-economic-indicators',
    UPDATE: 'update-economic-indicators',
    DESTROY: 'destroy-economic-indicators',
    RESTORE: 'restore-economic-indicators',
  },
  OFFER_BROUGHT_VIAS: {
    LIST: 'list-offer-brought-vias',
    STORE: 'store-offer-brought-vias',
    UPDATE: 'update-offer-brought-vias',
    DESTROY: 'destroy-offer-brought-vias',
    RESTORE: 'restore-offer-brought-vias',
  },
  OFFERS: {
    LIST: 'list-offers',
    STORE: 'store-offers',
    UPDATE: 'update-offers',
    DESTROY: 'destroy-offers',
    RESTORE: 'restore-offers',
  },
};
