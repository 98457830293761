import { emptyAsNull } from 'src/libs/yup/custom-methods/string';
import * as yup from 'yup';
import Reference from 'yup/lib/Reference';

import { validateDateFormat } from './custom-methods/date';
import {
  validateCpf,
  validateCnpj,
  validateCpfCnpj,
} from './custom-methods/document';
import equalTo from './custom-methods/equalTo';
import { isFile } from './custom-methods/file';
import { requiredWhen } from './custom-methods/required';
import translation from './translation';

yup.setLocale(translation);

yup.addMethod(yup.mixed, 'equalTo', equalTo);
yup.addMethod(yup.mixed, 'requiredWhen', requiredWhen);
yup.addMethod(yup.mixed, 'isFile', isFile);

yup.addMethod(yup.string, 'cpf', validateCpf);
yup.addMethod(yup.string, 'cnpj', validateCnpj);
yup.addMethod(yup.string, 'cpfCnpj', validateCpfCnpj);
yup.addMethod(yup.string, 'dateFormat', validateDateFormat);
yup.addMethod(yup.string, 'emptyAsNull', emptyAsNull);

export default yup;

declare module 'yup' {
  interface BaseSchema {
    equalTo(ref: Reference<any>, refLabel?: string): this;
    requiredWhen(ref: Reference<any>, refLabel?: string): this;
    isFile(msg?: string): this;
  }

  interface StringSchema {
    cpf(msg?: string): this;
    cnpj(msg?: string): this;
    cpfCnpj(msg?: string): this;
    dateFormat(format: string, msg?: string): this;
    emptyAsNull(): this;
  }
}
