import { ToastContainer } from 'react-toastify';
import styled, { css } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

export const Container = styled(ToastContainer)`
  ${({ theme }) => css`
    .Toastify__toast--info {
      background-color: ${theme.palette.info.main};
    }
    .Toastify__toast--success {
      background-color: ${theme.palette.success.main};
    }
    .Toastify__toast--warning {
      background-color: ${theme.palette.warning.main};
    }
    .Toastify__toast--error {
      background-color: ${theme.palette.error.main};
    }
  `}
`;
