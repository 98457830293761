import React from 'react';

import { AxiosRequestConfig } from 'axios';
import Autocomplete from 'src/components/Form/Autocomplete';
import { AutocompleteProps } from 'src/components/Form/Autocomplete/interfaces';
import IDepartment from 'src/interfaces/models/IDepartment';
import apiAdminPublic from 'src/services/api/adminPublic';

type AutocompleteDepartmentsProps = {
  requestConfig?: AxiosRequestConfig;
} & Omit<AutocompleteProps<IDepartment>, 'options'>;

const AutocompleteDepartments: React.FC<AutocompleteDepartmentsProps> = ({
  requestConfig,
  ...rest
}) => {
  const { isLoading, data: response } = apiAdminPublic.useDepartmentsQuery({
    requestConfig,
  });

  const options =
    response?.data?.map((department) => ({
      key: department.id,
      label: department.name,
      value: department.id,
      data: department,
    })) || [];

  return <Autocomplete {...rest} loading={isLoading} options={options} />;
};

export default AutocompleteDepartments;
