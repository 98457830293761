export const ATTENDANCE_COMMON_QUERY_KEYS = {
  REASONS: 'common-reasons',
  FUNNELS: 'common-funnels',
  FUNNELS_STEPS: 'common-funnels-steps',
  FUNNELS_ATTENDANTS: 'common-funnels-attendants',
  CLIENTS_OPPORTUNITIES: 'common-clients-opportunities',
};

export const ATTENDANCE_ADMIN_QUERY_KEYS = {
  REASONS: 'admin-reasons',
  FUNNELS: 'admin-funnels',
  FUNNELS_ATTENDANTS: 'admin-funnels-attendants',
  FUNNELS_STEPS: 'admin-funnels-steps',
  FUNNELS_OPPORTUNITIES: 'admin-funnels-opportunities',
  FUNNELS_OPPORTUNITIES_ATTENDANTS: 'admin-funnels-opportunities-attendants',
};
