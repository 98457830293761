import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useField } from '@unform/core';
import { TextFieldType } from 'src/components/Form/TextField/interfaces';
import masks from 'src/utils/masks';

import { Container } from './styles';

const TextField: FC<TextFieldType> = ({
  name,
  mask,
  type,
  label,
  InputLabelProps,
  onChange,
  onFocus,
  onBlur,
  returnUnmasked,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [labelShrink, setLabelShrink] = useState(false);

  const {
    fieldName,
    registerField,
    error,
    defaultValue = '',
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        const value = ref.current?.value || '';
        if (mask && returnUnmasked) {
          return masks.unmask(value);
        }
        return value;
      },
      setValue: (ref, value = '') => {
        if (mask && masks[mask]) {
          ref.current.value = masks[mask](value);
        } else {
          ref.current.value = value;
        }
        clearError();
        setLabelShrink(!!ref.current.value);
      },
      clearValue: (ref, newValue = '') => {
        ref.current.value = newValue;
        clearError();
        setLabelShrink(!!ref.current.value);
      },
    });
  }, [fieldName, registerField, mask, returnUnmasked, clearError]);

  useEffect(() => {
    if (inputRef.current && defaultValue) {
      if (mask && masks[mask]) {
        inputRef.current.value = masks[mask](defaultValue);
      } else {
        inputRef.current.value = defaultValue;
      }
      setLabelShrink(!!inputRef.current?.value);
    }
  }, [defaultValue, mask]);

  // To use masks
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      e.persist();
      const value = e.target.value || '';

      if (inputRef.current) {
        if (mask && masks[mask]) {
          inputRef.current.value = masks[mask](value);
        } else {
          inputRef.current.value = value;
        }
      }

      clearError();

      if (onChange) onChange(e);
    },
    [mask, onChange, clearError],
  );

  const handleFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setLabelShrink(true);

      if (onFocus) onFocus(e);
    },
    [onFocus],
  );

  const handleBlur: FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setLabelShrink(!!inputRef.current?.value);

      if (onBlur) onBlur(e);
    },
    [onBlur],
  );

  return (
    <Container
      inputRef={inputRef}
      label={label}
      InputLabelProps={{ ...InputLabelProps, shrink: labelShrink }}
      type={type}
      name={fieldName}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      error={!!error}
      helperText={error}
      aria-describedby={fieldName}
      fullWidth
      {...rest}
    />
  );
};

export default TextField;
